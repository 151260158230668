* {
  margin: 0;
  z-index: 0;
  
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: black;
  --tw-bg-opacity: 0;

}
p {
  line-height: 1.6;
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6{
font-family: 'Bebas Neue', sans-serif;
}

/* HEADER STYLING*/

.motto_styling{
  font-family: 'Nothing You Could Do', sans-serif;
}


.logo__size {
  height: 300px;
}
/* END OF HEADER STYLING*/

.photo__size {
 /* height: 400px;
  width: 533px;*/
  width: 100%;
}

.photo__row {
  display: flex;
  width: 100%;
height: auto;
  justify-content: center;
  margin-bottom: -4px;
  transition: ease all 0.5s;
 
}

.photo__container > img {
  cursor: pointer;
  opacity: 1;
  transition: opacity 400ms;
}

.photo__container {
  position: relative;
}

.photo__container > img:hover {
  opacity: 0.8;
  transition: 0.4s;
}

.photo__container > h1 {
  font-size: 1.3em;
  user-select: none;
}

.photo__container:hover .__hide {
  transition: 0.4s;
  opacity: 1;
}

.text__over__photo {
  position: absolute;
  left: 50%; /* horizontal alignment */
  top: 50%; /* vertical alignment */
  transform: translate(-50%, -50%);
  color:white;
}

h1 {
  background-color: rgb(255, 255, 255, 0);
}

.__hide {
  opacity: 0;
  transition: opacity 200ms;
}

.spotify__playlist {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

/*FOOTER PAGE -CSS */
.footer__layout {
  text-align: center;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #000000;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/*ARTISTS PAGE -CSS */


.flex__layout {
  display: flex;
  justify-content: center;
  margin-top:60px;
}

.artist__description {
  width: 500px;
  padding-right: 20px;
  padding-left: 20px;
}

.artists__photo__size {
  width: 400px;
}

.social__media__icons {
  display: flex;
  justify-content: start;
}

.social__media__icons {
  display: flex;
  justify-content: flex-start;
}

.social__media__icons p {
  margin: 10px;
}

.social__media__icons > li > a > p {
  transform: scale(1);
  transition: scale 500ms;
}

.social__media__icons > li > a > p:hover {
  transition: 0.2s;
  transform: scale(1.2);
}


li {
  list-style-type: none;
}

.__photos {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

/*Image-100% width */
/*flex display column */

@media screen and (max-width: 1020px) {
  .photo__row {
    flex-direction: column;
    align-items: center;
  }

  .logo__size {
    height: 200px;
  }

  .__blinded,
  .__aili {
    margin-bottom: -4px;
  }
}

@media screen and (max-width: 926px) {
  .flex__layout {
    flex-direction: column;
    align-items: center;
  }

  .social__media__icons {
    justify-content: center;
  }

  .artist__description {
    width: 90%;
  }
}

/*ARTISTS-COMPONENT (HOME) */
@media screen and (max-width: 800px) {
  .photo__container > h1 {
    font-size: 1em;
  }

  .photo__container > img {
    height: 75%;
    width: 100%;
  }

  .photo__row {
    align-items: center;
    justify-content: center;
  }

  /*FIXED NAVBAR
  
    .navbar__fixed__ui {
    position: absolute;
    top: -;
    z-index: 100;
    height: 200px;
    width: 100%;
    background-color: #ffffff !important;
  }
  
  */
}

@media screen and (max-width: 730px) {
  .artists__photo__size {
    width: 90%;
  }

  .__photos {
    align-items: center;
    padding-right: 0;
  }
}


/* FOOTER BOX SHADOW*/
.__footer{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 12px;
}